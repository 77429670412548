import { HeadFC, PageProps } from "gatsby"
import React from "react"
import { HeadSEO } from "../components/head/"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

const PoliticaDePrivacidadePage: React.FC<PageProps> = () => {
  return (
    <body>
      <div className="container header">
        <div className="row">
          <div className="d-flex flex-row py-5 justify-content-center col">
            <span className="h1 text-center">Políticas de privacidade</span>
          </div>
        </div>
      </div>
      <div className="container policy-content">
        <div className="row">
          <div className="col">
            <p>
              A presente Política de Privacidade tem por finalidade demonstrar o
              compromisso de, <strong>TROP FRUTAS DO BRASIL LTDA.</strong>.,
              pessoa jurídica de direito privado, inscrita no CNPJ/ME sob o nº
              07.757.005/0006-17 (“<strong>Verde Campo</strong>”) com a
              privacidade do Titular dentro do uso e da navegação de seu site,
              servindo a presente Política e Privacidade para regular e informar
              de forma simples, objetiva e transparente quais dados e
              informações serão obtidos e como poderão ser utilizados.
            </p>

            <p>
              Esta Política de Privacidade aplica-se a todos os usuários e
              visitantes do site, o objetivo é garantir o sigilo total das
              informações que o Titular fornece, estabelecendo regras sobre a
              coleta, registro, utilização, armazenamento, tratamento e
              compartilhamento.
            </p>

            <p>
              O presente documento foi elaborado em conformidade com a
              Constituição da República Federativa do Brasil, a Lei 10.406/2002
              (Código Civil), a Lei 12.925/2014 (Marco Civil da Internet), a Lei
              13.709/2018 (Lei Geral de Proteção de Dados Pessoais-LGPD) e
              demais normas aplicáveis. Ainda, o documento poderá ser atualizado
              em decorrência de eventual atualização normativa, razão pela qual
              se convida o usuário a consultar periodicamente esta política.
            </p>

            <strong>1. DEFINIÇÕES</strong>

            <p>Para todos os fins desta Política de Privacidade:</p>

            <p>
              <strong>Dados Pessoais:</strong> são os dados que permitem, direta
              ou indiretamente, a identificação de uma pessoa em particular,
              conforme especificamente discriminados nesta Política de
              Privacidade.
            </p>

            <p>
              <strong>Dados Pessoais Sensíveis:</strong> são os dados pessoais
              com maior potencial discriminatório, capazes de revelar
              informações como origem racial ou étnica, convicção religiosa,
              opinião política, filiação a sindicato ou a organização de caráter
              religioso, filosófico ou político, dado referente à saúde ou à
              vida sexual, dado genético ou biométrico.
            </p>

            <p>
              <strong>Dados Não Pessoais:</strong> são os dados e as informações
              que não permitem a identificação de seu titular ou que não se
              referem a pessoas que possam ser identificadas, também
              considerados como dados anônimos.
            </p>

            <p>
              <strong>Tratamento:</strong>significa toda operação realizada com
              dados pessoais, como as que se referem a coleta, produção,
              recepção, classificação, utilização, acesso, reprodução,
              transmissão, distribuição, processamento, arquivamento,
              armazenamento, eliminação, avaliação ou controle da informação,
              modificação, comunicação, transferência, difusão ou extração. 
            </p>

            <p>
              <strong>Finalidade:</strong> trata-se do objetivo para o qual a
              <strong> Verde Campo</strong>
              realizará o tratamento dos dados pessoais.
            </p>

            <p>
              <strong> Base Legal:</strong>fundamentação autorizada por lei para
              o tratamento dos dados pessoais.
            </p>

            <p>
              Compartilhamento: significa comunicação, difusão, transferência
              internacional, interconexão de dados pessoais ou tratamento
              compartilhado de bancos de dados pessoais por órgãos e entidades
              públicos no cumprimento de suas competências legais, ou entre
              esses e entes privados, reciprocamente, com autorização
              específica, para uma ou mais modalidades de tratamento permitidas
              por esses entes públicos, ou entre entes privados. 
            </p>

            <p>
              <strong>Titular:</strong> pessoa natural a quem se referem os
              dados pessoais, tais como antigos, presentes ou potenciais alunos,
              colaboradores, contratados, parceiros comerciais e terceiros.
            </p>

            <p>
              <strong>Fale Conosco:</strong> é uma das formas de entrar em
              contato com a <strong>Verde Campo</strong> disponível no endereço
              eletrônico{" "}
              <a href="https://verdecampo.com.br/contato.">
                https://verdecampo.com.br/contato.
              </a>
               
            </p>

            <p>
              <strong>Site:</strong> é o site da Verde Campo disponível no
              endereço eletrônico{" "}
              <a href="https://verdecampo.com.br/">
                https://verdecampo.com.br/.
              </a>
            </p>

            <p className="h6">
              <strong>2. COLETA DE DADOS PESSOAIS</strong>
            </p>

            <p>
              {" "}
              <strong>
                Os Dados Pessoais do Titular coletados são especificamente:,
              </strong>{" "}
              (i) nome completo; (ii) e-mail; (iii) telefone; (iv) Cidade e
              Estado/UF. 
            </p>

            <p>
              <strong>2.2. Dados Pessoais coletados automaticamente:</strong>{" "}
              Dados Pessoais coletados automaticamente mediante a utilização de
              tecnologias padrões, como cookies, pixel tags, beacons e local
              shared objects, com o propósito de melhorar a experiência de
              navegação do usuário e visitantes nos serviços, de acordo com seus
              hábitos e suas preferências.
            </p>

            <p>
              Os Dados Pessoais coletados automaticamente são informações de
              geolocalização e informações referentes ao dispositivo utilizado
              para navegar ou utilizar o Site, como por exemplo: endereços IP,
              tipo de navegador e idioma, provedor de serviços de internet,
              páginas de consulta e saída, sistema operacional, informações
              sobre a data e horário, dados sobre a sequência de clicks, ações
              no Site, fabricante do dispositivo, operadora, modelo, redes Wi-Fi
              e número de telefone.
            </p>

            <p>
              É possível desabilitar, por meio das configurações de seu
              navegador de internet, a coleta automática de informações,
              conforme item 6. No entanto, o Titular deve estar ciente de que,
              se desabilitar essas tecnologias, alguns recursos oferecidos pelo
              Site, que dependem do tratamento dos referidos dados, poderão não
              funcionar corretamente.
            </p>

            <p>
              O acesso a seus Dados Pessoais é restrito aos colaboradores da
              Verde Campo e a seus parceiros comerciais, nos estritos limites
              desta Política de Privacidade, e nenhuma informação pessoal será
              divulgada publicamente.
            </p>

            <p>
              <strong>2.3. Dados Sensíveis</strong>
            </p>

            <p>
              Os dados pessoais do usuário e do visitante coletados e
              armazenados por este site, tem por finalidade atender as
              reclamações realizadas por seus clientes, aprimorar o serviço
              oferecido, facilitar, agilizar e cumprir os compromissos
              estabelecidos entre o usuário e a empresa, melhorar a experiência
              dos usuários, fornecer funcionalidades específicas a depender das
              características básicas do usuário, atender nossas obrigações
              legais e/ou regulatórias, permitir o exercício regular de nossos
              direitos, viabilizar atividades necessárias ou de apoio à Verde
              Campo, como por exemplo, a melhoria de nossos produtos, executar
              um contrato, evitar fraudes e zelar pela sua segurança, por fim,
              também poderemos utilizar os seus Dados Pessoais para criar
              segmentações de públicos-alvo e, assim, promover de forma mais
              adequada conteúdos atualizados de campanhas de marketing
              publicitário.
            </p>

            <p>
              O tratamento de dados pessoais para finalidades não previstas
              nessa Política de Privacidade somente ocorrerá mediante
              comunicação prévia ao usuário, de modo que os direitos e
              obrigações aqui previstos permanecem aplicáveis.
            </p>

            <p>USO E COMPARTILHAMENTO DOS DADOS</p>

            <p>
              Os dados coletados poderão ser utilizados e compartilhados pela
              Verde Campo para as seguintes finalidades:
            </p>

            <p>
              1. Serviços de atendimento ao cliente: o canal de atendimento ao
              cliente funcionará de segunda a sexta-feira das 8h às 18h, sendo
              que aos sábados, domingos e feriados não haverá expediente. 
            </p>

            <p>
              1. Retirada e/ou substituição de produtos: a Verde Campo poderá
              compartilhar os dados do Titular para que seja realizada visita de
              retirada e substituição de produtos reclamados. 
            </p>

            <p>
              1. Correspondência eletrônica: a Verde Campo poderá utilizar o
              endereço de e-mail para enviar mensagens de respostas referente ao
              contato realizado pelo consumidor.
            </p>

            <p>
              1. Detecção e prevenção de fraudes: podemos usar seus Dados
              Pessoais para detectar e prevenir fraudes e outras atividades
              ilegais ou indesejadas.
            </p>

            <p>
              1. Cumprimento de ordem legal ou judicial: a Verde Campo atenderá,
              independentemente de comunicação prévia ao Titular, qualquer
              autoridade judicial competente que tenha por fim determinar que
              seja revelada a identidade do Titular ou quaisquer dados
              fornecidos à Verde Campo ou por ela coletados.
            </p>

            <p>
              1. Cumprimento de solicitação administrativa: a Verde Campo poderá
              fornecer informações ao solicitante das informações, desde que
              autorizado pelo Titular, salvo quando a solicitação de tratar de
              obrigação legal ou estiver fundada em qualquer uma das bases
              legais previstas da Lei Geral de Proteção de Dados Pessoais;
            </p>

            <p>
              1. Melhoria dos nossos serviços: a Verde Campo utiliza seus dados
              pessoais para fins de análise, melhoria e aprimoramento das
              Unidades.
            </p>

            <p>
              1. Reorganização Societária: na hipótese de realização de
              quaisquer operações societárias envolvendo a Verde Campo, tais
              como fusão, reestruturação, incorporação, venda de ações e/ou
              quaisquer ativos, o Titular, desde já, concorda que seus dados
              poderão ser transferidos, desde que respeitados os termos desta
              Políticas de Privacidade.
            </p>

            <p>DO ARMAZENAMENTO</p>

            <p>
              Os dados pessoais dos usuários e visitantes são armazenados pela
              plataforma durante o período necessário para a prestação do
              serviço ou cumprimento das finalidades previstas no presente
              documento, conforme o disposto no inciso I do artigo 15 da Lei
              Geral de Proteção de Dados Pessoais.
            </p>

            <p>
              Os dados poderão ser removidos ou anonimizados mediante
              solicitação do usuário, exceto nos casos em que a lei oferecer
              outro tipo de tratamento.
            </p>

            <p>
              A Verde Campo poderá ainda, manter conservados os dados fornecidos
              pelos usuários, após o término de seu tratamento, nas hipóteses
              previstas no Artigo 16 da mesma lei.
            </p>

            <p>1. COOKIES</p>

            <p>
              Um cookie é uma pequena quantidade de dados colocada no navegador
              de seu computador ou no seu dispositivo móvel. A Política de
              Privacidade e Cookies se aplicam a cookies instalados nos
              navegadores e também às tecnologias similares ou análogas contidas
              nos aplicativos para dispositivos móveis (aqui referidas,
              genericamente, como “cookies”).
            </p>

            <p>
              Os cookies permitem que você seja reconhecido como o mesmo usuário
              navegando pelas páginas de um website. 
            </p>

            <p>
              Em sua navegação no website, poderão ser utilizados 04 (quatro)
              tipos de cookies:
            </p>

            <p>
              1. Autenticação: servem para reconhecer um determinado usuário,
              possibilitando o acesso e utilização do website com conteúdo e/ou
              serviços restritos e proporcionando experiências de navegação mais
              personalizadas.
            </p>

            <p>
              1. Segurança: são utilizados para ativar recursos de segurança dos
              websites, com a finalidade de auxiliar o monitoramento e/ou
              detecção de atividades maliciosas ou vedadas por esta Política de
              Privacidade, bem como de proteger as informações do usuário do
              acesso por terceiros não autorizados.
            </p>

            <p>
              1. Pesquisa, Análise e Desempenho: a finalidade deste tipo de
              cookie é ajudar a entender o desempenho do website, medir a
              audiência do website, verificar os hábitos de navegação do usuário
              no website, bem como a forma pela qual chegou à página do website
              (por exemplo, através de links de outros websites, buscadores ou
              diretamente pelo endereço).
            </p>

            <p>
              O Titular poderá desabilitar o uso de cookies e limpar cache
              utilizando as configurações disponíveis em seu dispositivo.
              Contudo, ao optar por essa alternativa, é possível que o Site não
              desempenhe 100% das suas funcionalidades.
            </p>

            <p>
              Para mais informações sobre como proceder em relação à gestão dos
              cookies nos navegadores:
            </p>

            <li>
              Internet Explorer:
              <a href="ttps://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies">
                https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies 
              </a>
            </li>

            <li>
              Mozilla Firefox:{" "}
              <a href=" https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam">
                https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam 
              </a>
            </li>

            <li>
              Google Chrome:{" "}
              <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR">
                https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR 
              </a>
            </li>

            <li>
              Safari:{" "}
              <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
                https://support.apple.com/pt-br/guide/safari/sfri11471/mac
              </a>
            </li>

            <p>1. SEGURANÇA</p>

            <p>
              A plataforma se compromete a aplicar medidas técnicas e
              organizativas aptas a proteger os Dados Pessoais e as informações
              coletados, mantendo o seu armazenamento de forma segura e sigilosa
              enquanto considerados relevantes, seja pela manutenção de seu uso,
              seja pela suspeita de relevância legal das informações ou pelo
              continuado interesse nas estatísticas geradas a partir dessas
              informações.
            </p>

            <p>
              Aqueles que entrarem em contato com os Dados Pessoais do Titular
              agirão com confidencialidade e sigilo absoluto.
            </p>

            <p>
              Toda a equipe Verde Campo, seus parceiros e prestadores de
              serviços são devidamente instruídos para, quando for o caso,
              tratar os dados armazenados do Titular. A Verde Campo faz todo o
              controle de acesso às informações armazenadas, delimitando as
              permissões de acesso.
            </p>

            <p>
              A Verde Campo possui proteção contra acesso não autorizado a seus
              sistemas e utiliza métodos padrão de Criptografia SSL, cujo o
              acesso à base de dados é restrita à empresa e a profissionais
              previamente autorizados.
            </p>

            <p>
              A Verde Campo garante aos Titulares exatidão, clareza, relevância
              e atualização dos Dados Pessoais, de acordo com a necessidade e
              para o cumprimento da finalidade de seu tratamento.
            </p>

            <p>
              A Verde Campo adota os melhores esforços no sentido de preservar a
              privacidade dos dados do Titular. Entretanto nenhum site e/ou
              aplicativo é totalmente seguro e a Verde Campo não pode garantir
              integralmente que todas as informações que trafegam no Site não
              sejam alvo de acessos não autorizados perpetrados por meio de
              métodos desenvolvidos para obter informações de forma indevida.
              Por esse motivo, a Verde Campo incentiva e recomenda que os
              Titulares tomem apropriadas para se proteger, como por exemplo,
              mantendo confidenciais todos os nomes de usuários e senhas.
            </p>

            <p>1. DIREITOS DOS TITULARES</p>

            <p>
              Os Titulares poderão, por meio de envio de e-mail
              dpo@leaoalimentosebebidas.com.br, solicitar à Verde Campo,
              exclusivamente com relação a seus próprios Dados Pessoais (a) a
              confirmação da existência de tratamento dos dados; (b) o acesso
              aos dados; (c) a correção dos dados incompletos, inexatos ou
              desatualizados; (d) a obtenção de informações sobre as entidades
              públicas ou privadas com as quais a Verde Campo tenha
              compartilhados os dados; (e) anonimização, bloqueio ou eliminação
              dos dados; (f) portabilidade dos dados, nos termos da lei; e (g) a
              revogação do seu consentimento a esta Política.
            </p>

            <p>1. DO CONSENTIMENTO</p>

            <p>
              Ao utilizar os serviços e fornecer as informações pessoais na
              plataforma, o usuário consentirá automaticamente com a Política de
              Privacidade.
            </p>

            <p>
              O Titular poderá solicitar a revogação do seu consentimento a esta
              Política de Privacidade a qualquer tempo, mediante contato através
              do endereço eletrônico dpo@leaoalimentosebebidas.com.br, poderá
              solicitar ainda, a exclusão de dados essenciais, porém fica ciente
              o usuário que a ação poderá resultar na impossibilidade de
              continuação do vínculo com a Verde Campo. A Verde Campo
              empreenderá todos os esforços para atender tais pedidos no menor
              tempo possível. No entanto, mesmo em caso de exclusão e/ou
              revogação da aceitação a esta Política de Privacidade, será
              respeitado o prazo mínimo de armazenamento de dados e informações
              de usuários de aplicações de internet, conforme determinado pela
              legislação brasileira vigente.
            </p>

            <p>1. ACEITAÇÃO</p>

            <p>
              O Titular declara expressamente ter lido, compreendido e estar de
              acordo com esta Política de Privacidade.
            </p>

            <p>
              O Titular declara que está de acordo com a coleta dos dados, bem
              como com a utilização e compartilhamento para as finalidades aqui
              estabelecidas.
            </p>

            <p>
              A Verde Campo reserva-se no direito de alterar esta Política de
              Privacidade a qualquer momento, devendo manter atualizada e
              disponível no site{" "}
              <a href="https://verdecampo.com.br/">
                https://verdecampo.com.br/
              </a>
              . Nesse caso o Titular será informado quanto às alterações
              realizadas, conforme exigido pela legislação brasileira vigente,
              sendo altamente recomendável a leitura periódica e integral da
              presente Política de Privacidade.
            </p>

            <p>
              Qualquer oposição que o Titular tenha sobre as alterações desta
              Política de Privacidade poderão ser manifestadas, por escrito,
              através do endereço eletrônico{" "}
              <a href="dpo@leaoalimentosebebidas.com.br">
                {" "}
                dpo@leaoalimentosebebidas.com.br
              </a>{" "}
              ou por meio do Fale Conosco{" "}
              <a href="https://verdecampo.com.br/contato">
                https://verdecampo.com.br/contato
              </a>
              , sendo que a recusa desta Política de Privacidade e de suas
              eventuais alterações implicará ao Titular a impossibilidade de
              celebração de qualquer relação contratual com a Verde Campo.
            </p>

            <p>1. LEGISLAÇÅO E FORO</p>

            <p>
              Esta Política de Privacidade será regida, interpretada e executada
              de acordo com as leis brasileiras, especialmente, mas não se
              limitando à, a Lei Geral de Proteção de Dados nº 13.709/2018,
              sendo competente o foro do domicílio do Titular para dirimir
              qualquer dúvida decorrente deste instrumento.
            </p>
          </div>
        </div>
      </div>
    </body>
  )
}

export default PoliticaDePrivacidadePage

export const Head: HeadFC = () =>
  HeadSEO(capitalizeFirstLetter(`Política de Privacidade | Verde Campo`))
